import { Button, Card, Col, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { Content } from "antd/es/layout/layout";
import { apiToken, BaseUrl } from "../../Common/Apis/Apis";
import { FaCopy } from "react-icons/fa";
import { IoMdCopy } from "react-icons/io";
import toast, { ToastBar } from "react-hot-toast";
import WithdralRequestModal from "../WithdralRequest/WithdralRequestModal";

const UserWithdralRequest = () => {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchWithdrawalData = async () => {
    const apiToken = localStorage?.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}admin/getWithdrawalTransaction`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setWithdrawalData(response.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching withdrawal data:", error);
      setLoading(false);
    }
  };
  const formatAddress = (address) => {
    return `${address?.slice(0, 6)}...${address?.slice(-4)}`;
  };
  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Copied");
  };

  useEffect(() => {
    fetchWithdrawalData();
  }, []);

  return (
    <div className="container-fluid w-75 mt-5">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Withdrawal Requests" loading={loading}>
            <Table>
              <thead>
                <tr className="text-start">
                  <th>Date and Time</th>
                  <th>Wallet</th>
                  <th>USDC Amount</th>
                  <th>Status</th>
                  <th>Txid</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!loading && withdrawalData?.length > 0 ? (
                  withdrawalData.map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        {new Date(transaction.createdAt).toLocaleString()}
                      </td>
                      <td>
                        {formatAddress(transaction.wallet_address)}{" "}
                        <IoMdCopy
                          className="text-primary"
                          onClick={() =>
                            copyToClipboard(transaction.wallet_address)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>{transaction.usdc_withdrawal_amount}</td>
                      <td
                        className={
                          transaction.status === "APPROVED"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {transaction.status}
                      </td>
                      <td>
                        {transaction.transaction_hash
                          ? formatAddress(transaction.transaction_hash)
                          : ""}
                        {transaction.transaction_hash && (
                          <IoMdCopy
                            className="text-primary"
                            onClick={() =>
                              copyToClipboard(transaction.transaction_hash)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </td>
                      <td>
                        <a
                          href="?#"
                          className="withdraw-btn text-decoration-none text-danger"
                          style={{ marginRight: "15px" }}
                        >
                          Reject
                        </a>
                        <WithdralRequestModal walletAddress={transaction.wallet_address} tokenName={"USDC"} tokenBalances={transaction.usdc_withdrawal_amount} usdValue={"1"}/>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {loading ? "Loading..." : "No transactions found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserWithdralRequest;

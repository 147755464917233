import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { GiCheckMark } from "react-icons/gi";
import { FaCopy } from "react-icons/fa6";
import OTPInput from "react-otp-input";
import { TbReload } from "react-icons/tb";
import { SiGoogleauthenticator } from "react-icons/si";
import { Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import authenticator from "../../../Assets/Svg/google-authenticator.svg";
import Gauthenticator from "../../../Assets/Images/Google_Authenticator_for_Android_icon.png";
import QRCode from "qrcode.react";
import { BaseUrl } from "../../Common/Apis/Apis";
import Swal from "sweetalert2";

function Authenticator() {
  const [show, setShow] = useState(false);
  const [qrGenerated, setQrGenerated] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [otp, setOtp] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("secretInput");
    input.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const qrGenerate = async () => {
    setLoading(true);
    const apiToken = localStorage?.getItem("token");
    try {
      const response = await axios.get(BaseUrl + "admin/generate_qr", {
        headers: {
          Authorization: apiToken.replace(/^"|"$/g, ""),
        },
      });
      setQrGenerated(response?.data?.data?.secret);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       qrGenerate();
  //     }, 60000); // Refresh QR code every 1 minute

  //     return () => clearInterval(interval);
  //   }, []);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const verifyAuth = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("token"));

      const response = await axios.get(BaseUrl + `admin/verify_qr?otp=${otp}`, {
        headers: {
          Authorization: token,
        },
      });
      toast.success("Two Factor Authentication Enabled!");
      localStorage.setItem("enable_auth", JSON.stringify("true"));
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error?.response?.data?.error || "Error verifying OTP");
    }
  };
  return (
    <>
      <span onClick={handleShow} style={{ cursor: "pointer" }}>
        <SiGoogleauthenticator className="text-primary"/> Authenticator
      </span>

      <Modal
        show={show}
        onHide={handleClose}
        className="authModal g_authentication"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <span className={`f-${currentStep === 1 ? 40 : 22}`}>
            {currentStep === 1 && "Google Authentication"}
            {currentStep === 2 &&
              "Scan this QR code in the Google Authenticator app"}
            {currentStep === 3 && "Google Authentication Code"}
          </span>
          {currentStep === 1 && (
            <img
              src={authenticator}
              alt=""
              width={25}
              className="ms-3 align-self-center"
            />
          )}
        </Modal.Header>
        <Modal.Body>
          <div>
            {currentStep === 1 && (
              <div className="text-center">
                <p className="mb-3 f-20 text-start text-muted">
                  Please download and install the Google Authenticator app on
                  your phone
                </p>
                <div className="d-flex mt-5">
                  <div
                    className="col-12 col-md-8 d-flex flex-column justify-content-center p-4 rounded bg-light"
                    style={{ lineHeight: "23px" }}
                  >
                    <ol className="text-start">
                      <li>
                        Download and install the Google Authenticator app.
                      </li>
                      <li>Open the app and click "Begin setup" or "+" sign.</li>
                      <li>
                        Click "Next" and scan the QR code or manually enter the
                        provided code.
                      </li>
                      <li>
                        The app will automatically add the account and generate
                        OTPs.
                      </li>
                    </ol>
                  </div>
                  <div className="col-12 col-md-4 position-relative d-flex align-items-center justify-content-center">
                    <img
                      src={Gauthenticator}
                      alt=""
                      className="img-fluid"
                      width={200}
                    />
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="text-center">
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    {qrGenerated?.base32 === undefined ? (
                      <TbReload
                        className="border rounded-circle bg-light p-3 text-dark"
                        onClick={qrGenerate}
                        style={{
                          cursor: "pointer",
                          marginTop: "10px",
                          fontSize: "70px",
                        }}
                      />
                    ) : (
                      <div>
                        <QRCode
                          value={`otpauth://totp/SuiDoller?secret=${qrGenerated?.base32}&hex=${qrGenerated?.hex}&ascii=${qrGenerated?.ascii}`}
                          style={{ height: "136px", width: "136px" }}
                        />
                        <TbReload
                          onClick={qrGenerate}
                          style={{
                            cursor: "pointer",
                            marginTop: "10px",
                            color: "#00ADEF",
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                <p className="f-16 mt-3 text-start text-muted">
                  If you are unable to scan the QR code, please enter this code
                  manually into the app.
                </p>
                <div className="input-group mb-3">
                  <input
                    id="secretInput"
                    type="text"
                    className="form-control"
                    value={
                      qrGenerated?.base32 === undefined
                        ? " reload"
                        : `otpauth://totp/SuiDoller?secret=${qrGenerated?.base32}&hex=${qrGenerated?.hex}&ascii=${qrGenerated?.ascii}`
                    }
                    readOnly
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={copyToClipboard}
                  >
                    {copied ? (
                      <GiCheckMark style={{ color: "#00ADEF" }} />
                    ) : (
                      <FaCopy style={{ color: "#00ADEF" }} />
                    )}
                  </button>
                </div>
                <span className="f-14 text-muted text-start">
                  Please save this key. This key will allow you to recover your
                  Google Authenticator in case of phone loss.
                </span>
              </div>
            )}
            {currentStep === 3 && (
              <div className="text-center">
                <p className="mb-3 f-20 text-start text-muted">
                  Enter the OTP received in your Google Authenticator app
                </p>
                <div className="d-flex justify-content-center auth-passwords mt-4">
                  <OTPInput
                    value={otp}
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    renderInput={(props, index) => (
                      <input {...props} key={index} />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          {currentStep > 1 && (
            <Button onClick={handlePrevious} className="w-25 supplyBtn">
              Previous
            </Button>
          )}
          {currentStep < 3 && (
            <Button onClick={handleNext} className="w-25 supplyBtn">
              Next
            </Button>
          )}
          {currentStep === 3 && (
            <Button onClick={verifyAuth} className="w-25 supplyBtn">
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Authenticator;

import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Tooltip } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "../Supply/SupplyModal.css";
import { useWallet } from "@suiet/wallet-kit";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { Button } from "react-bootstrap";
import { withdrawApprovel } from "./WithdrawApprovelContract";

const WithdralRequestModal = ({ tokenBalances, tokenName, usdValue, walletAddress }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const wallet = useWallet();
  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }
    if (calculatedBalance > tokenBalances) {
      toast.error("Entered Value is more then Balance.");
      return;
    }
    // contract

    try {
      const result = await withdrawApprovel(
        calculatedBalance,
        walletAddress,
        wallet
      );
      handleCancel();
      console.log(result);
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setCalculatedBalance("");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    setCalculatedBalance(value);
  };

  const [config, setConfig] = useState(null);

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  useEffect(() => {
    loadConfig();
  }, []);
  return (
    <>
      <a
        className="withdraw-btn text-decoration-none text-success"
        onClick={showModal}
      >
        Approve
      </a>

      <Modal
        title={`${tokenName} Withdraw`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets supplied">
              <div className="d-flex align-items-center text-secondary">
                Amount <MdOutlineInfo className="text-secondary ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                  ${(calculatedBalance * usdValue)?.toFixed(2) || "0.00"}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  value={tokenBalances || ""}
                  onChange={handleInputChange}
                />
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={USDC}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            disabled={calculatedBalance === 0}
            className="supplyBtn w-100"
            onClick={handleClick}
          >
            {`Withdraw ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default WithdralRequestModal;

import React, { useState } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "../Withdraw/WithdrawModal.css";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { useWallet } from "@suiet/wallet-kit";
import { withdrawfee } from "./WithdrawFee";
import toast from "react-hot-toast";

const WithdrawModalFee = ({ feeBalance, handleReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const wallet = useWallet();
  const balance = feeBalance / 1e6;
  const tokenName = "USDC";

  const handleClick = async () => {
    if (!wallet) {
      toast.error("Please connect your Sui wallet first.");
      return;
    }
    if (amount > balance) {
      toast.error("Entered Value is more then Navi Supplied Balance.");
      return;
    }
    try {
      const result = await withdrawfee(wallet, amount, tokenName);
      handleCancel();
      handleReload();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    const newAmount = (value / 100) * balance;
    setAmount(newAmount);
  };

  const handleMaxClick = () => {
    setAmount(balance);
    setSliderValue(100);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      value = 0;
    }
    setAmount(value);
    setSliderValue((value / balance) * 100);
  };

  const marks = {
    0: "0",
    25: "25",
    50: "50",
    75: "75",
    100: "100",
  };

  return (
    <>
      <span className="me-2" onClick={showModal}>
        Fee Withdraw
      </span>
      <Modal
        title={`${tokenName} Fee Treasury Withdrawal `}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets supplied">
              <div className="d-flex align-items-center text-secondary">
                Amount <MdOutlineInfo className="text-secondary ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-end">
                {/* <span>$0</span> */}
                <span>Fee Treasury USDC Balance: {balance?.toFixed(4)}</span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  min={0}
                  max={balance}
                  value={amount} // Bind input value to state
                  onChange={handleInputChange} // Update state on input change
                />
                <button className="max-btn" onClick={handleMaxClick}>
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={USDC}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            className="supplyBtn"
            onClick={handleClick}
            disabled={amount <= 0 || amount > parseFloat(balance)}
          >
            {amount <= 0 ? "Enter Amount" : `Withdraw ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default WithdrawModalFee;

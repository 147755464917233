import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import Logo from "../../Assets/Images/LogoLight.png";
import ConnectWallet from "./ConnectWallet";
import { Dropdown, Menu, Input, Modal, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";
import { SlOptions } from "react-icons/sl";
import Authenticator from "../Auth/Authenticator/Authenticator";
import toast from "react-hot-toast";
import { BaseUrl } from "../Common/Apis/Apis";
import axios from "axios";
import { CgLockUnlock } from "react-icons/cg";
import Swal from "sweetalert2";
import { TbPasswordFingerprint } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { GrTransaction } from "react-icons/gr";
import { BiMoneyWithdraw } from "react-icons/bi";

const { Password } = Input;
function Hadder() {
  const navigate = useNavigate();
  const [enableAuth, setEnableAuth] = useState("false");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const apiToken = localStorage.getItem("token");
  const UserToken = localStorage.getItem("UserAuth");

  React.useEffect(() => {
    const storedAuth = localStorage.getItem("enable_auth");
    setEnableAuth(storedAuth ? JSON.parse(storedAuth) : "false");
  }, []);
  const handleTransaction = () => {
    navigate("/admin/admin_transaction");
  };
  const handleUserTransaction = () => {
    navigate("/transaction_history");
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, stay login!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        navigate("/admin/login");
        Swal.fire({
          title: "Logged Out",
          text: "You have been logged out successfully.",
          icon: "success",
        });
      }
    });
  };

  const handleDisableAuth = async () => {
    const apiToken = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + "admin/disableAndEnable_Auth",
        { auth: false },
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken ? apiToken.replace(/^"|"$/g, "") : "",
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Authenticator disabled successfully!");
      localStorage.setItem("enable_auth", JSON.stringify("false"));
      setEnableAuth("false");
      console.log(response?.data);
    } catch (error) {
      toast.error("Failed to disable authenticator.");
      console.error(error.response ? error.response.data : error.message);
    }
  };

  const handleButtonClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to disable the authenticator. This may affect account security!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disable it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDisableAuth();
        Swal.fire("Disabled!", "Authenticator has been disabled.", "success");
      }
    });
  };

  const handleChangePwd = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { oldPassword, newPassword, confirmPassword } = values;
        if (newPassword !== confirmPassword) {
          message.error("New passwords do not match.");
          return;
        }
        const apiToken = localStorage.getItem("token");
        try {
          const response = await axios.post(
            BaseUrl + "admin/changePassword",
            {
              oldPassword: oldPassword,
              newPassword: newPassword,
            },
            {
              headers: {
                accept: "application/json",
                Authorization: apiToken ? apiToken.replace(/^"|"$/g, "") : "",
                "Content-Type": "application/json",
              },
            }
          );
          message.success("Password changed successfully!");
          setIsModalVisible(false);
          console.log(response?.data);
        } catch (error) {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
          message.error("Failed to change password. Please try again.");
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const page = useLocation().pathname;

  return (
    <>
      <Navbar expand="lg" className="bg-body-nav">
        <Container fluid>
          <Navbar.Brand
            href="/"
            className="d-flex align-items-center text-white ms-md-3"
          >
            <img src={Logo} alt="" className="image-fluid me-3" width={150} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <SlOptions className="text-light" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <ul>
              <li>
                <a href="/vault" className={page === "/vault" ? "/" : ""}>
                  USDC Vault
                </a>
              </li>
              <li>
                <a href="?#">Docs</a>
              </li>
            </ul>
          </Navbar.Collapse>
          <ConnectWallet />

          <Dropdown
            placement="bottom"
            className="mx-3 text-light border px-2 py-1 rounded-circle"
            overlay={
              <Menu>
                {UserToken && (
                  <Menu.Item key="Transaction" onClick={handleUserTransaction}>
                    <GrTransaction className="text-success" /> Transaction
                    History
                  </Menu.Item>
                )}
                {apiToken && (
                  <>
                    <Menu.Item key="auth">
                      {enableAuth === "true" ? (
                        <span
                          onClick={handleButtonClick}
                          style={{ cursor: "pointer" }}
                        >
                          <CgLockUnlock className="text-danger" /> Disable
                          Authenticator
                        </span>
                      ) : (
                        <Authenticator />
                      )}
                    </Menu.Item>
                    <Menu.Item key="changePwd" onClick={handleChangePwd}>
                      <TbPasswordFingerprint className="text-primary" /> Change
                      Password
                    </Menu.Item>
                    <Menu.Item key="Withdraw" onClick={handleTransaction}>
                      <BiMoneyWithdraw className="text-success" /> Withdraw
                      Request
                    </Menu.Item>
                    <Menu.Item key="logout" onClick={handleLogout}>
                      <FaPowerOff className="text-danger" /> Logout
                    </Menu.Item>
                  </>
                )}
              </Menu>
            }
          >
            <span style={{ cursor: "pointer" }}>
              <SlOptions />
            </span>
          </Dropdown>
        </Container>
      </Navbar>

      <Modal
        title="Change Password"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Change"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              { required: true, message: "Please enter your old password!" },
            ]}
          >
            <Password placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: "Please enter your new password!" },
            ]}
          >
            <Password placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            rules={[
              { required: true, message: "Please confirm your new password!" },
            ]}
          >
            <Password placeholder="Confirm New Password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Hadder;

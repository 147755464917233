import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Tooltip } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "../Supply/SupplyModal.css";
import { useWallet } from "@suiet/wallet-kit";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";
import { UserdepositContract } from "./UserdepositContract";
import USDC from "../../../Assets/Svg/Usdc.svg"
import { Button } from "react-bootstrap";

const UserDepositModal = ({
  tokenName,
  tokenBalances,
  usdValue,
  Object_Id,
  fetchBalances,
  netWorth,
  userbalances
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const wallet = useWallet();
  console.log(netWorth,"netWorth2");
  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }
    if (calculatedBalance > tokenBalances) {
      toast.error("Entered Value is more then Wallet Balance.");
      return;
    }

    try {
      const result = await UserdepositContract(
        wallet,
        calculatedBalance,
        Object_Id,
        netWorth,
        userbalances
      );
      handleCancel();
      fetchBalances()
      console.log(result);
      setTimeout(() => {
        fetchBalances();
      }, 3000);
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setCalculatedBalance("");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMaxClick = () => {
    setCalculatedBalance(tokenBalances.toString());
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    // value = Math.max(0, Math.min(value, tokenBalances));
    // const percentage = (value / tokenBalances) * 100;

    setCalculatedBalance(value);
  };

  const [config, setConfig] = useState(null);

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  useEffect(() => {
    loadConfig();
  }, []);
  return (
    <>
        <span
          className="me-2"
          disabled={!wallet.connected && !wallet.account?.address}
          onClick={showModal}
        >
          Deposit
        </span>

      <Modal
        title={`${tokenName} Vault Deposit `}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="USDC amount to be deposited">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                  {/*${(calculatedBalance * usdValue)?.toFixed(2) || "0.00"}*/}
                </span>
                <span>
                  Wallet Balance: {tokenBalances}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  value={calculatedBalance}
                  onChange={handleInputChange}
                />
                <button
                  className="max-btn"
                  type="button"
                  onClick={handleMaxClick}
                >
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={USDC}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Button
            disabled={calculatedBalance <= 0 || calculatedBalance > parseFloat(tokenBalances)}
            className="supplyBtn w-100"
            onClick={handleClick}
          >
            {calculatedBalance <= 0 ? "Enter Amount" : `Deposit ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default UserDepositModal;

import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";
import axios from "axios";
import { apiToken, BaseUrl } from "../../Common/Apis/Apis";

export async function withdrawApprovel(
  calculatedBalance,
  walletAddress,
  wallet
) {
  const config = await getConfig();
  const txb = new Transaction();
  const amountInNanoUnits = Math.floor(calculatedBalance * 1e6);
  const tokenDetails = {
    USDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.USDC.type,
    },
  };
  const selectedToken = tokenDetails["USDC"];

  txb.moveCall({
    target: `${config.FairmilePackage}::fairmile_navi::withdrawUSDCUser`,
    arguments: [
      txb.object(config.usdcTreasury),
      txb.pure.address(walletAddress),
      txb.pure.u64(calculatedBalance),
    ],
    typeArguments: [selectedToken.type],
  });

  try {
    const result = await wallet.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });
    console.log(result, "result");
    //api here
    toast.success("Transaction successful!");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Rejected from user"
        : error.message || "Transaction failed!"
    );
    console.error("Error executing transaction:", error);
  }
}

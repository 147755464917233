import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  WalletProvider,
  defineStashedWallet,
  SuiWallet,
  SuietWallet,
  SurfWallet
} from "@suiet/wallet-kit";
import "@suiet/wallet-kit/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WalletProvider defaultWallets={[
      defineStashedWallet({
        appName: "Fairmiles",
      }),
      SuiWallet,
      SuietWallet,
      SurfWallet
    ]}>
      <App />
    </WalletProvider>
  </React.StrictMode>
);

reportWebVitals();

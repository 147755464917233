import React, { useState } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "./BorrowModal.css";
import { useWallet } from "@suiet/wallet-kit";
import { BorrowCoinWithAccountCap } from "./BorrowToken";
import { BaseUrl, getIndexAssetData } from "../../Common/Apis/Apis";
import axios from "axios";


const BorrowModal = ({
  tokenLogo,
  tokenName,
  CoinObjectId,
  balances,
  borrowAmt,
  usdValue,
  handleReload,
  borrowreload
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const [health, setHealth] = useState("-");

  const assets = Object.values(usdValue)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
      asset: info.symbol,
      info,
    }));

  const borrowValueSui =
    (borrowAmt?.SUI?.supplyBalance * 0.7 - borrowAmt?.SUI?.borrowBalance) *
    assets[0]?.info?.price;
  const borrowValueUsdc =
    (borrowAmt?.USDC?.supplyBalance * 0.7 - borrowAmt?.USDC?.borrowBalance) *
    assets[1]?.info?.price;
  const borrowValueWeth =
    (borrowAmt?.WETH?.supplyBalance * 0.7 - borrowAmt?.WETH?.borrowBalance) *
    assets[2]?.info?.price;
  const totalBorrow = borrowValueSui + borrowValueUsdc + borrowValueWeth;
  const formattedBalance =
    tokenName === "SUI"
      ? totalBorrow / assets[0]?.info?.price
      : tokenName === "USDC"
      ? totalBorrow / assets[1]?.info?.price
      : totalBorrow / assets[2]?.info?.price;
  const balance = formattedBalance;
  const wallet = useWallet();

  const usdPrice =
    tokenName === "SUI"
      ? assets[0]?.info?.price
      : tokenName === "USDC"
      ? assets[1]?.info?.price
      : assets[2]?.info?.price;

  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }
    try {
      const result = await BorrowCoinWithAccountCap(wallet, amount, tokenName);
      handleReload();
      borrowreload();
      handleCancel();
      console.log(result);
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  var newHealthFactorId = "new_health_factor_borrow_"+tokenName;
  const showModal = () => {
    setIsModalOpen(true);
    setAmount("");
    fetchHealth();
    var newHF = document.getElementById(newHealthFactorId);
    if (newHF != null) {
      newHF.textContent = "";
    }
    //setSliderValue(0);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //const handleSliderChange = (value) => {
    //setSliderValue(value);
    //const newAmount = (value / 100) * balance;
    //setAmount(newAmount);
  //};

  const handleMaxClick = () => {
    setAmount(balance);
    updateHealthFactor(balance)
    //setSliderValue(100);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      value = 0;
    }
    // value = Math.max(0, Math.min(value, balance));
    setAmount(value);
    updateHealthFactor(value)
    //setSliderValue((value / balance) * 100);
  };

  const mode = process.env.REACT_APP_MODE;
  const naviAddAccountCap =
    mode === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getHealthFactor?key=${naviAddAccountCap}`
      );
      setHealth(response?.data?.data?.health.toFixed(2) || "0.00");
    } catch (err) {
      console.error(err.message);
      setHealth("0.00");
    }
  };

  const suiUsdDebt = borrowAmt?.SUI?.borrowBalance * assets[0]?.info?.price;
  const usdcUsdDebt = borrowAmt?.USDC?.borrowBalance * assets[1]?.info?.price;
  const wethUsdDebt = borrowAmt?.WETH?.borrowBalance * assets[2]?.info?.price;

  const suiUsdSupply = borrowAmt?.SUI?.supplyBalance * assets[0]?.info?.price;
  const usdcUsdSupply = borrowAmt?.USDC?.supplyBalance * assets[1]?.info?.price;
  const wethUsdSupply = borrowAmt?.WETH?.supplyBalance * assets[2]?.info?.price;

  const suiLiqThreshold = 0.8;
  const usdcLiqThreshold = 0.85;
  const wethLiqThreshold = 0.8;

  const updateHealthFactor = (value) => {
    var newHF = 0;
    if (tokenName == "SUI") {
      const newSuiUsdDebt = suiUsdDebt + value * assets[0]?.info?.price;
      const newTotalUsdDebt = newSuiUsdDebt + usdcUsdDebt + wethUsdDebt;
      newHF = ((suiUsdSupply * suiLiqThreshold) + (usdcUsdSupply * usdcLiqThreshold) + (wethUsdSupply * wethLiqThreshold))/newTotalUsdDebt;
    }
    if (tokenName == "USDC") {
      const newUsdcUsdDebt = usdcUsdDebt + value * assets[1]?.info?.price;
      const newTotalUsdDebt = suiUsdDebt + newUsdcUsdDebt + wethUsdDebt;
      newHF = ((suiUsdSupply * suiLiqThreshold) + (usdcUsdSupply * usdcLiqThreshold) + (wethUsdSupply * wethLiqThreshold))/newTotalUsdDebt;
    }
    if (tokenName == "WETH") {
      const newWethUsdDebt = wethUsdDebt + value * assets[2]?.info?.price;
      const newTotalUsdDebt = suiUsdDebt + usdcUsdDebt + newWethUsdDebt;
      newHF = ((suiUsdSupply * suiLiqThreshold) + (usdcUsdSupply * usdcLiqThreshold) + (wethUsdSupply * wethLiqThreshold))/newTotalUsdDebt;
    }
    var healthElement = document.getElementById("new_health_factor_borrow_"+tokenName);
    if (parseFloat(newHF) <= 100) {
      healthElement.textContent = parseFloat(newHF).toFixed(2);
    }
    else {
      healthElement.textContent = "Infinity";
    }
  };

  const marks = {
    0: "0",
    25: "25",
    50: "50",
    75: "75",
    100: "100",
  };

  return (
    <>
      <span className="me-2" onClick={showModal}>
        Borrow
      </span>
      <Modal
        title={`Borrow ${tokenName}`}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets to borrow">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>${(amount * usdPrice).toFixed(2) || "0.00"}</span>
                <span>
                  {tokenName} Available:{" "}
                  {balance.toFixed(4)}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  min={0}
                  max={balance}
                  value={amount} // Bind input value to state
                  onChange={handleInputChange} // Update state on input change
                />
                <button className="max-btn" onClick={handleMaxClick}>
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={tokenLogo}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          {/*<Slider
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
          />*/}
          <Form.Item>
            <span>Transaction Overview</span>
            <div className="transaction-overview">
              <div className="mb-2 d-flex justify-content-between">
                <span>Current Health Factor</span>
                <span>{health}</span>
              </div>
              <div className="mb-2 d-flex justify-content-between">
                <span>Borrow Amount</span>
                <span>{amount <= 0 ? "" : amount}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>New Health Factor</span>
                <span id={newHealthFactorId}></span>
              </div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            className="supplyBtn"
            onClick={handleClick}
            disabled={amount <= 0 || amount > parseFloat(balance)}
          >
            {amount <= 0 ? "Enter Amount" : `Borrow ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default BorrowModal;

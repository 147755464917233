import React, { useEffect, useState } from "react";
import { Card, Dropdown, Menu, Tooltip } from "antd";
import { Table } from "react-bootstrap";
import { MdOutlineInfo } from "react-icons/md";
import WithdrawModal from "../../Components/Modal/Withdraw/WithdrawModal";
import SUI from "../../Assets/Svg/Sui.svg";
import VSUI from "../../Assets/Svg/VSui.avif";
import USDC from "../../Assets/Svg/Usdc.svg";
import WETH from "../../Assets/Svg/Weth.svg";
import Empty from "../../Assets/Svg/Empty.svg";
import { BsThreeDots } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import "./Navi.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useWallet } from "@suiet/wallet-kit";
import {
  BaseUrl,
  getIndexAssetData,
  SuivisionDefi,
} from "../../Components/Common/Apis/Apis";
import RewardModal from "../../Components/Modal/Reward/RewardModal";

const Supplied = ({
  CoinObjectId,
  stakes,
  loading,
  fetchStakes,
  usdValue,
  SetSupplyedUsd,
  SetBorrowAmt,
  setReload,
  withdrawReload
}) => {
  const [isRotating, setIsRotating] = useState(false);
  const [vSui, setVSui] = useState();
  const wallet = useWallet();
  const Owner = process.env.REACT_APP_MODE;
  const OwnerWallet =
    Owner === "DEV"
      ? process.env.REACT_APP_DEV_ALLOWED_ADDRESS
      : process.env.REACT_APP_PROD_ALLOWED_ADDRESS;

  const assetsssss = Object.values(usdValue)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
      asset: info.symbol,
      info,
    }));

  const assets = [
    {
      name: "SUI",
      icon: SUI,
      balance: `${stakes?.SUI?.supplyBalance?.toFixed(4) || 0.0}`,
      apy:
        (
          parseFloat(assetsssss[0]?.info?.supply_rate || 0) +
          parseFloat(assetsssss[0]?.info?.boosted || 0)
        )?.toFixed(2) + "%",
      ltv: "70.00%",
    },
    {
      name: "USDC",
      icon: USDC,
      balance: `${stakes?.USDC?.supplyBalance?.toFixed(4) || 0.0}`,
      apy:
        (
          parseFloat(assetsssss[1]?.info?.supply_rate || 0) +
          parseFloat(assetsssss[1]?.info?.boosted || 0)
        )?.toFixed(2) + "%",
      ltv: "70.00%",
    },
    {
      name: "WETH",
      icon: WETH,
      balance: `${stakes?.WETH?.supplyBalance?.toFixed(5) || 0.0}`,
      apy:
        (
          parseFloat(assetsssss[2]?.info?.supply_rate || 0) +
          parseFloat(assetsssss[2]?.info?.boosted || 0)
        )?.toFixed(2) + "%",
      ltv: "70.00%",
    },
  ];

  const filteredAssets = assets.filter((asset) => {
    if (asset.name === "WETH") {
      return asset.balance > 0.0;
    }
    return asset.balance > 0.0;
  });

  const handleReload = () => {
    fetchStakes();
    fetchTokenInfo();
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 5000);
  };

  const [assetsInfo, setAssetsInfo] = useState({});
  const fetchTokenInfo = async () => {
    try {
      const response = await axios.get(getIndexAssetData);
      setAssetsInfo(response.data);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };
  const assetsBal = Object.values(assetsInfo)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
      price: info.price,
      name: info.symbol,
    }));

  useEffect(() => {
    fetchTokenInfo();
    handleReload();
    SetBorrowAmt(stakes);
    setReload(() => handleReload);
  }, []);

  const SuiUsdBal = stakes?.SUI?.supplyBalance * assetsBal[0]?.price;
  const USDCUsdBal = stakes?.USDC?.supplyBalance * assetsBal[1]?.price;
  const WETHUsdBal = stakes?.WETH?.supplyBalance * assetsBal[2]?.price;
  const tokenUsdBal = USDCUsdBal + WETHUsdBal + SuiUsdBal;

  useEffect(() => {
    SetSupplyedUsd(tokenUsdBal || "0.00");
    SetBorrowAmt(stakes);
    fetchData();
  }, [tokenUsdBal, SetSupplyedUsd]);

  const NaviAccCap =
    Owner === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;
  const fetchData = async () => {
    try {
      const response = await axios.get(
        BaseUrl+`client/edgesuivision`,
        {
          headers: {
            accept: "application/json, text/plain, */*",
          },
        }
      );
      setVSui(response?.data?.data?.result?.rewards[0]?.value);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Card
      title={
        <div className="d-flex justify-content-between">
          <span>Navi Supplies</span>
          <h6 className="d-flex align-items-center">
            Total Supplies:&nbsp;<strong>${wallet.connected ? tokenUsdBal?.toFixed(2) || "0.00" : "0.00"}</strong>&nbsp;&nbsp;
              {wallet.connected && (
                <FiRefreshCcw
                  style={{ cursor: "pointer" }}
                  onClick={handleReload}
                  className={isRotating ? "rotateReload" : ""}
                />
              )}
          </h6>
        </div>
      }
    >
      {wallet.connected ? (
        filteredAssets.length === 0 ? (
          <div className="text-center">
            <img src={Empty} alt="" width={100} />
            <br />
            <span className="text-muted">Nothing supplied yet</span>
          </div>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Balance</th>
                <th>APY</th>
                <th>Max LTV</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredAssets.map((asset, index) => (
                <tr key={index}>
                  <td>
                    <img src={asset.icon} alt={asset.name} /> <span>{asset.name}</span>
                  </td>
                  <td>{asset.balance}</td>
                  <td className="text-success">{asset.apy}</td>
                  <td>{asset.ltv}</td>
                  <td>
                    <Dropdown
                      placement="bottom"
                      overlay={
                        wallet?.account?.address === OwnerWallet ? (
                          <Menu>
                            <Menu.Item key="withdraw">
                              <WithdrawModal
                                tokenLogo={
                                  asset.name === "SUI"
                                    ? SUI
                                    : asset.name === "USDC"
                                    ? USDC
                                    : WETH
                                }
                                stakes={stakes}
                                tokenName={asset.name}
                                balance={asset.balance}
                                CoinObjectId={CoinObjectId}
                                handleReload={() => handleReload()}
                                assetsBal={assetsBal}
                                withdrawReload={withdrawReload}
                              />
                            </Menu.Item>
                          </Menu>
                        ) : (
                          <Menu>
                            <Menu.Item key="withdraw">
                              <span
                                className="me-2"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Incorrect Wallet Address",
                                    text: "Please connect with the same origin wallet address.",
                                    icon: "question",
                                  });
                                }}
                              >
                                Navi Withdraw
                              </span>
                            </Menu.Item>
                          </Menu>
                        )
                      }
                    >
                      <BsThreeDots />
                    </Dropdown>
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ textAlign: "left" }}>
                  <img src={VSUI} alt={"vSui"} width={32} />{" "}
                  vSUI
                </td>
                {/* <td>{vSui?.value}</td> */}
                <td>{vSui}</td>
                <td className="text-success">{}</td>
                <td>{}</td>
                <td>
                  <Dropdown
                    placement="bottom"
                    overlay={
                      wallet?.account?.address === OwnerWallet ? (
                        <Menu>
                          <Menu.Item key="reward">
                            <RewardModal
                              tokenLogo={VSUI}
                              // stakes={stakes}
                              tokenName={"VSUI"}
                              balance={vSui}
                              CoinObjectId={vSui?.coinType}
                              handleReload={() => handleReload()}
                            />
                          </Menu.Item>
                        </Menu>
                      ) : (
                        <Menu>
                          <Menu.Item key="reward">
                            <span
                              className="me-2"
                              onClick={() => {
                                Swal.fire({
                                  title: "Incorrect Wallet Address",
                                  text: "Please connect with the same origin wallet address.",
                                  icon: "question",
                                });
                              }}
                            >
                              Claim Reward
                            </span>
                          </Menu.Item>
                        </Menu>
                      )
                    }
                  >
                    <BsThreeDots />
                  </Dropdown>
                </td>
              </tr>
            </tbody>
          </Table>
        )
      ) : (
        <div className="text-center">
          <img src={Empty} alt="" width={100} />
          <br />
          <span className="text-muted">Nothing supplied yet</span>
        </div>
      )}
    </Card>
  );
};

export default Supplied;

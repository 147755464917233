import React, { useEffect } from "react";
import { ConnectButton, useWallet, ErrorCode } from "@suiet/wallet-kit";
import "./Navbar.css";
import toast from "react-hot-toast";
import axios from "axios";
import { BaseUrl } from "../Common/Apis/Apis";

function ConnectWallet() {
  const wallet = useWallet();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${BaseUrl}client/login`, {
        walletAddress: wallet?.account?.address,
      });
      // toast.success(response?.data?.message);
      localStorage.setItem("UserAuth", JSON.stringify(response?.data?.data));
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong.");
    }
  };

  useEffect(() => {
    if (wallet.connected && wallet.account?.address) {
      localStorage.setItem("walletAddress", wallet.account.address);
      handleLogin();
    } else {
      localStorage.removeItem("walletAddress");
    }
  }, [wallet.connected, wallet.account?.address]);

  return (
    <div>
      <ConnectButton
        onConnectError={(error) => {
          if (error.code === ErrorCode.WALLET__CONNECT_ERROR__USER_REJECTED) {
            console.warn(
              "user rejected the connection to " + error.details?.wallet
            );
            toast.error(
              "User rejected the connection to " + error.details?.wallet
            );
          } else {
            console.warn("Unknown connect error: ", error);
            toast.error("Unknown connect error: " + error.message);
          }
        }}
      >
        Connect Wallet
      </ConnectButton>
    </div>
  );
}

export default ConnectWallet;

import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from 'react-hot-toast';

export async function depositCoinWithAccountCap(wallet, calculatedBalance, CoinObjectId, tokenName, fetchTreasureWethBalance,
fetchTreasureUsdcBalance,
fetchTreasureBalance) {
    const config = await getConfig();
    const txb = new Transaction();
    const amountInNanoUnits = tokenName === "SUI" 
        ? Math.floor(calculatedBalance * 1e9)
        : tokenName === "USDC" 
        ? Math.floor(calculatedBalance * 1e6) 
        : Math.floor(calculatedBalance * 1e8);
    const tokenDetails = {
        SUI: {
            poolId: pool.Sui.poolId,
            assetId: pool.Sui.assetId,
            type: pool.Sui.type,
            coinObjectId: CoinObjectId?.SuiCoinObjectId || ""
        },
        USDC: {
            poolId: pool.USDC.poolId,
            assetId: pool.USDC.assetId,
            type: pool.USDC.type,
            coinObjectId: CoinObjectId?.UsdcCoinObjectId || ""
        },
        WETH: {
            poolId: pool.WETH.poolId,
            assetId: pool.WETH.assetId,
            type: pool.WETH.type,
            coinObjectId: CoinObjectId?.WethCoinObjectId || ""
        }
    };

    const selectedToken = tokenDetails[tokenName];

    if (tokenName === "SUI") {
        // const [coinObject] = txb.splitCoins(txb.gas, [amountInNanoUnits]); // Split SUI gas and deposit amount
        txb.moveCall({
            target: `${config.FairmilePackage}::fairmile_navi::depositSuiNavi`,
            arguments: [
                txb.object(config.suiTreasury), // suiTreasury object id
                txb.object(config.myStruct), // myStruct object id
                txb.object(config.ClockID), // clock object id
                txb.object(config.StorageId), // object id of storage
                txb.object(selectedToken.poolId), // pool id of the asset
                txb.pure.u8(selectedToken.assetId), // the id of the asset in the protocol
                txb.pure.u64(amountInNanoUnits), // the object id of the Coin you own
                txb.object(config.Incentive),
                txb.object(config.IncentiveV2), // The incentive object v2
            ],
            typeArguments: [selectedToken.type],
        });
    } else if (tokenName === "USDC"){
        // const [coinObject] = txb.splitCoins(txb.object(selectedToken.coinObjectId), [amountInNanoUnits]); // Split non-SUI token amount
        txb.moveCall({
            target: `${config.FairmilePackage}::fairmile_navi::depositUsdcNavi`,
            arguments: [
                txb.object(config.usdcTreasury), // AccountCap Object
                txb.object(config.myStruct), // myStruct object id
                txb.object(config.ClockID), // clock object id
                txb.object(config.StorageId), // object id of storage
                txb.object(selectedToken.poolId), // pool id of the asset
                txb.pure.u8(selectedToken.assetId), // the id of the asset in the protocol
                txb.pure.u64(amountInNanoUnits), // the object id of the Coin you own
                txb.object(config.Incentive),
                txb.object(config.IncentiveV2), // The incentive object v2
            ],
            typeArguments: [selectedToken.type],
        });
    } else{
        // const [coinObject] = txb.splitCoins(txb.object(selectedToken.coinObjectId), [amountInNanoUnits]); // Split non-SUI token amount
        txb.moveCall({
            target: `${config.FairmilePackage}::fairmile_navi::depositWethNavi`,
            arguments: [
                txb.object(config.wethTreasury), // AccountCap Object
                txb.object(config.myStruct), // myStruct object id
                txb.object(config.ClockID), // clock object id
                txb.object(config.StorageId), // object id of storage
                txb.object(selectedToken.poolId), // pool id of the asset
                txb.pure.u8(selectedToken.assetId), // the id of the asset in the protocol
                txb.pure.u64(amountInNanoUnits), // the object id of the Coin you own
                txb.object(config.Incentive),
                txb.object(config.IncentiveV2), // The incentive object v2
            ],
            typeArguments: [selectedToken.type],
        });
    }

    try {
        const result = await wallet.signAndExecuteTransactionBlock({ transactionBlock: txb });
        fetchTreasureWethBalance();
        fetchTreasureUsdcBalance();
        fetchTreasureBalance();
        toast.success('Transaction successful');
    } catch (error) {
        toast.error(error.message=== "[WALLET.SIGN_TX_ERROR] Rejected by user" ? "Rejected by user": error.message || 'Transaction failed');
        console.error('Error executing transaction:', error);
    }
}

import React from "react";
import {
  Layout,
  Card,
  Row,
  Col,
  Divider,
  Button,
  Tooltip,
  Progress,
  Alert,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import USDC from "../../Assets/Svg/Usdc.svg";
import SUI from "../../Assets/Svg/Sui.svg";
import WETH from "../../Assets/Svg/Weth.svg";
import { Link, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { MdOutlineInfo } from "react-icons/md";

const { Content } = Layout;

function MarketDetail() {
  const { tokenName } = useParams();
  return (
    <Layout>
      <Content style={{ padding: "20px 50px" }}>
        <Container fluid>
            <div className="d-flex align-items-center mb-3">
              <Button
                type="link"
                className="border rounded-pill border-secounder me-3"
                onClick={() => window.history.back()}
              >
                <FaArrowLeftLong /> Back
              </Button>
              <h6 className="m-0 d-flex align-items-center">
                <img src={SUI} alt="" className="img-fluid me-2" width={25}/>
                SUI Market
              </h6>
            </div>
          <Card bordered={false}>
            <Row gutter={16} className="justify-content-evenly">
              <Col span={4} className="d-flex align-items-center">
                <img src={tokenName === "SUI" ? SUI : tokenName === "USDC" ? USDC : WETH } alt="USDC" className="img-fluid" />
                <div className="ms-3">
                  <h6>{tokenName}</h6>
                  <h5>{tokenName === "SUI" ? "SUI" : tokenName === "USDC" ? "USD Coin" : "Wrapped Ether"}</h5>
                </div>
              </Col>
              <Col span={4}>
                <h6>Reserve Size</h6>
                <h5>
                  <strong>$31,056,456.83</strong>
                </h5>
              </Col>
              <Col span={4}>
                <h6>Available Liquidity</h6>
                <h5>
                  <strong>$14.74M</strong>
                </h5>
              </Col>
              <Col span={4}>
                <h6>Utilization Rate</h6>
                <h5>
                  <strong>52.52%</strong>
                </h5>
              </Col>
              <Col span={4}>
                <h6>Oracle Price</h6>
                <h5>
                  <strong>$0.999995</strong>
                </h5>
              </Col>
            </Row>
          </Card>
          <Divider />
          <Row gutter={16}>
            <Col span={16}>
              <Card title="Reserve Status & Configuration" bordered={false}>
                <Row gutter={16}>
                  <Col
                    span={8}
                    className="text-center d-flex align-items-center"
                  >
                    <h4 className="me-3">Supply Info</h4>
                    <Progress
                      type="circle"
                      percent={47.78}
                      format={(percent) => `${percent}%`}
                    />
                  </Col>
                  <Col
                    span={16}
                    className="d-flex justify-content-evenly align-items-center"
                  >
                    <div>
                      <h4>Total Supplied</h4>
                      <p>
                        31.05M of 65M{" "}
                        <Tooltip title="Total amount of assets supplied">
                          <MdOutlineInfo />
                        </Tooltip>
                      </p>
                    </div>
                    <div>
                      <h4>APY</h4>
                      <p style={{ color: "#ff4d4f" }}>
                        9.48%{" "}
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "#d9d9d9",
                          }}
                        >
                          4.41%
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row span={6} className="align-items-center my-3">
                  <h6>Collateral Usage</h6>
                  <p className="mb-1 text-success ms-2">
                    <FaCheck /> Can be collateral
                  </p>
                </Row>
                <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={8}>
                    <Card>
                      <h6>Max LTV</h6>
                      <p>
                        70%{" "}
                        <Tooltip title="The Maximum LTV ratio represents the maximum borrowing power of a specific collateral. For example, if a collateral has an LTV of 75%, the user can borrow up to 0.75 worth of ETH in the principal currency for every 1 ETH worth of collateral.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </p>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <h6>Liquidation Threshold</h6>
                      <p>
                        85%{" "}
                        <Tooltip title="This represents the threshold at which a borrow position will be considered undercollateralized and subject to liquidation for each collateral. For example, if a collateral has a liquidation threshold of 80%, it means that the position will be liquidated when the debt value is worth 80% of the collateral value.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </p>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card>
                      <h6>Liquidation Penalty</h6>
                      <p>
                        5%{" "}
                        <Tooltip title="When a liquidation occurs, liquidators repay up to 50% of the outstanding borrowed amount on behalf of the borrower. In return, they can buy the collateral at a discount and keep the difference (liquidation penalty) as a bonus.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </p>
                    </Card>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={16} className="mt-3 d-flex align-items-center">
                  <Col
                    span={8}
                    className="text-center d-flex align-items-center"
                  >
                    <h4 className="me-3">Borrow Info</h4>
                    <Progress
                      type="circle"
                      percent={57.24}
                      format={(percent) => `${percent}%`}
                    />
                  </Col>
                  <Col span={16} className="d-flex justify-content-evenly">
                    <div>
                      <h4>Total Borrowed</h4>
                      <p>
                        16.4M of 28.11M{" "}
                        <Tooltip title="Total amount of assets supplied">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </p>
                    </div>
                    <div>
                      <h4>APY</h4>
                      <p style={{ color: "#ff4d4f" }}>10.50% </p>
                    </div>
                    <div>
                      <h4>Borrow Cap</h4>
                      <p>
                        28.11M{" "}
                        <Tooltip title="Total amount of assets supplied">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row span={6} className="align-items-center my-3">
                  <h6>Collateral Info</h6>
                </Row>
                <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={8}>
                    <Card>
                      <h6>Reserve Factor</h6>
                      <p>
                        20%{" "}
                        <Tooltip title="The Maximum LTV ratio represents the maximum borrowing power of a specific collateral. For example, if a collateral has an LTV of 75%, the user can borrow up to 0.75 worth of ETH in the principal currency for every 1 ETH worth of collateral.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </p>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Your Info" bordered={false}>
                <Alert
                  className="mb-4"
                  message={`Your SUI wallet is empty.`}
                  // <Link to="/">Get tokens</Link>
                  type="warning"
                  showIcon
                />
                <p>
                  Your SUI wallet is empty. <Link to="/">Get tokens</Link>
                </p>
                <Row gutter={16}>
                  <Col span={12} className="d-flex">
                    {/* <div className="border rounded-circle my-auto p-3">
                      <WalletOutlined />
                    </div> */}
                    <div>
                      <h6>Wallet Balance</h6>
                      <p>0 USDC</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <Button type="primary" className="w-100 mt-1">
                      Supply
                    </Button>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "20px" }}>
                  <Col span={12}>
                    <h6>Available To Borrow</h6>
                    <p>0 USDC</p>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="default"
                      style={{ width: "100%", marginTop: "30px" }}
                    >
                      Borrow
                    </Button>
                  </Col>
                </Row>
                <Divider style={{ borderColor: "#3b3f46" }} />
                <Row gutter={16}>
                  <Col span={12}>
                    <h4>Total Supply</h4>
                    <p>0 USDC</p>
                  </Col>
                  <Col span={12}>
                    <h4>Total Debt</h4>
                    <p>0 USDC</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
}

export default MarketDetail;

import React from "react";
import { Card, Flex } from "antd";
import { useWallet } from "@suiet/wallet-kit";
import { Container, Table } from "react-bootstrap";
import "./User.css";

const UserDashboard = () => {
  const wallet = useWallet();
  return (
    <div>
      <h1 className="my-5">Delta Neutral USDC Vault</h1>
      <Flex gap="middle" align="start" vertical>
        <Card title="Vault Information" className="vault-table">
          <Table>
            <tbody>
              <tr className="text-start">
                <td>Total Value Locked (TVL)</td>
                <td>1,000,000 USDC</td>
              </tr>
              <tr className="text-start">
                <td>APY</td>
                <td className="text-success">
                  <strong>37.07%</strong>
                </td>
              </tr>
              <tr className="text-start">
                <td>Vault Capacity</td>
                <td>2,000,000 USDC</td>
              </tr>
              <tr className="text-start">
                <td>Value of User Position</td>
                <td>10,542.33 USDC</td>
              </tr>
              <tr className="text-start">
                <td>Deposit Fee</td>
                <td>0.25%</td>
              </tr>
              <tr className="text-start">
                <td>Withdrawal Fee</td>
                <td>None</td>
              </tr>
              <tr className="text-start">
                <td>Withdrawal Processing</td>
                <td>Within 72 Hours</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Flex>
    </div>
  );
};

export default UserDashboard;

import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import toast from "react-hot-toast";

export async function RepayCoinWithAccountCap(wallet, amount, tokenName) {
  const config = await getConfig();
  const walletAddress = wallet.account.address;

  const amountInNanoUnits =
    tokenName === "SUI"
      ? Math.floor(amount * 1e9)
      : tokenName === "USDC"
      ? Math.floor(amount * 1e6)
      : Math.floor(amount * 1e8);

  const tokenDetails = {
    SUI: {
      poolId: pool.Sui.poolId,
      assetId: pool.Sui.assetId,
      type: pool.Sui.type,
    },
    USDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.USDC.type,
    },
    WETH: {
      poolId: pool.WETH.poolId,
      assetId: pool.WETH.assetId,
      type: pool.WETH.type,
    },
  };
  const selectedToken = tokenDetails[tokenName];
  const txb = new Transaction();
  if (tokenName === "SUI") {

    const [ret] = txb.moveCall({
      target: `${config.FairmilePackage}::fairmile_navi::repaySUI`,
      arguments: [
        txb.object(config.suiTreasury), // suiTreasury object id
        txb.object(config.myStruct), // myStruct object id
        txb.object(config.ClockID), // clock object id
        txb.object(config.PriceOracle), // object id of storage
        txb.object(config.StorageId), // object id of storage
        txb.object(selectedToken.poolId), // pool id of the asset
        txb.pure.u8(selectedToken.assetId), // the id of the asset in the protocol
        txb.pure.u64(amountInNanoUnits), // the object id of the Coin you own
        txb.object(config.IncentiveV2), // The incentive object v2
      ],
      typeArguments: [selectedToken.type],
    });
    const [coin] = txb.moveCall({
      target: `0x2::coin::from_balance`,
      arguments: [txb.object(ret)],
      typeArguments: [selectedToken.type],
    });
    txb.transferObjects([coin], walletAddress); // Withdraw to MyWallet
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else if (tokenName === "USDC") {
    // const [coinObject] = txb.splitCoins(txb.object(selectedToken.coinObjectId), [amountInNanoUnits]); // Split non-SUI token amount
    const [ret] = txb.moveCall({
      target: `${config.FairmilePackage}::fairmile_navi::repayUSDC`,
      arguments: [
        txb.object(config.usdcTreasury), // suiTreasury object id
        txb.object(config.myStruct), // myStruct object id
        txb.object(config.ClockID), // clock object id
        txb.object(config.PriceOracle), // object id of storage
        txb.object(config.StorageId), // object id of storage
        txb.object(selectedToken.poolId), // pool id of the asset
        txb.pure.u8(selectedToken.assetId), // the id of the asset in the protocol
        txb.pure.u64(amountInNanoUnits), // the object id of the Coin you own
        txb.object(config.IncentiveV2), // The incentive object v2
      ],
      typeArguments: [selectedToken.type],
    });
    const [coin] = txb.moveCall({
      target: `0x2::coin::from_balance`,
      arguments: [txb.object(ret)],
      typeArguments: [selectedToken.type],
    });
    txb.transferObjects([coin], walletAddress); // Withdraw to MyWallet
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful!");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else {
    // const [coinObject] = txb.splitCoins(txb.object(selectedToken.coinObjectId), [amountInNanoUnits]); // Split non-SUI token amount
    const [ret] = txb.moveCall({
      target: `${config.FairmilePackage}::fairmile_navi::repayWETH`,
      arguments: [
        txb.object(config.wethTreasury), // suiTreasury object id
        txb.object(config.myStruct), // myStruct object id
        txb.object(config.ClockID), // clock object id
        txb.object(config.PriceOracle), // object id of storage
        txb.object(config.StorageId), // object id of storage
        txb.object(selectedToken.poolId), // pool id of the asset
        txb.pure.u8(selectedToken.assetId), // the id of the asset in the protocol
        txb.pure.u64(amountInNanoUnits), // the object id of the Coin you own
        txb.object(config.IncentiveV2), // The incentive object v2
      ],
      typeArguments: [selectedToken.type],
    });
    const [coin] = txb.moveCall({
      target: `0x2::coin::from_balance`,
      arguments: [txb.object(ret)],
      typeArguments: [selectedToken.type],
    });
    txb.transferObjects([coin], walletAddress); // Withdraw to MyWallet
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  }
}

import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";
import axios from "axios";
import { apiToken, BaseUrl } from "../../Common/Apis/Apis";

export async function UserdepositContract(
  wallet,
  calculatedBalance,
  Object_Id,
  netWorth,
  userbalances
) {
  const config = await getConfig();
  const txb = new Transaction();
  const amountInNanoUnits = Math.floor(calculatedBalance * 1e6);
  
  const tokenDetails = {
      USDC: {
          poolId: pool.USDC.poolId,
          assetId: pool.USDC.assetId,
          type: pool.USDC.type,
        },
    };
  const selectedToken = tokenDetails["USDC"];
  const feeAmountInNanoUnits = Math.floor((amountInNanoUnits * 25) / 10000);
  const remainingAmountInNanoUnits = amountInNanoUnits - feeAmountInNanoUnits;
  const [coinObject] = txb.splitCoins(txb.object(Object_Id?.coinObjectId), [
    remainingAmountInNanoUnits,
  ]);

  txb.moveCall({
    target: `${config.FairmilePackage}::fairmile_navi::depositUSDCUser`,
    arguments: [
      txb.object(config.usdcTreasury), // USDC treasury
      txb.object(config.usdcFeeTreasury), // Fee treasury
      coinObject, // Remaining amount after fee
    ],
    typeArguments: [selectedToken.type],
  });

  try {
    const result = await wallet.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });
    console.log(result, "result");
    const apiResponse = await axios.post(
      BaseUrl + "client/depositTransaction",
      {
        walletAddress: wallet.address,
        depositFeeAmount: feeAmountInNanoUnits / 1e6,
        totalUserPosition: userbalances / 1e6,
        contractNetWorth: netWorth / 1e6,
        usdcAmount: remainingAmountInNanoUnits / 1e6,
        TransactionHash: result.digest,
      },
      {
        headers: {
          Authorization: apiToken?.token,
          "Content-Type": "application/json",
        },
      }
    );
    toast.success("Transaction successful!");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Rejected from user"
        : error.message || "Transaction failed!"
    );
    console.error("Error executing transaction:", error);
  }
}

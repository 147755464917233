import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { GiGasPump } from "react-icons/gi";
import { MdOutlineInfo } from "react-icons/md";
import "../Supply/SupplyModal.css";
import { useWallet } from "@suiet/wallet-kit";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";
import { Reward } from "./Reward";
const RewardModal = ({
  tokenName,
  CoinObjectId,
  handleReload,
  tokenLogo,
  balance,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const [config, setConfig] = useState(null);
  const wallet = useWallet();

  const tokenBalances = balance
  const handleClick = async () => {
    if (!wallet) {
      toast.error("Please connect your Sui wallet first.");
      return;
    }
    if (calculatedBalance > tokenBalances) {
      toast.error("Entered Value is more then Reward Balance.");
      return;
    }
    try {
      const result = await Reward(
        wallet,
        calculatedBalance,
        CoinObjectId,
      );
      handleOk();
      handleReload();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
    setCalculatedBalance("");
    //setSliderValue(0);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //const handleSliderChange = (value) => {
    //setSliderValue(value);
    //const balance = (value / 100) * tokenBalances;
    //setCalculatedBalance(balance.toFixed(2));
  //};

  const handleMaxClick = () => {
    setCalculatedBalance(tokenBalances);
    //setSliderValue(100);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      value = 0;
    }
    setCalculatedBalance(value);
  };
//useEffect(() => {
//  const balance = (sliderValue / 100) * tokenBalances;
//  setCalculatedBalance(balance);
//}, [sliderValue, tokenBalances]);
//const marks = {
//  0: "0",
//  25: "25",
//  50: "50",
//  75: "75",
//  100: "100",
//};
  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  useEffect(() => {
    loadConfig();
  }, []);
  return (
    <>
      {(tokenName === "VSUI" && config?.suiTreasury) ||
      (tokenName === "VSUI" && config?.usdcTreasury) ||
      (tokenName === "VSUI" && config?.wethTreasury) ? (
        <span
          className="me-2 "
          disabled={!wallet.connected && !wallet.account?.address}
          onClick={showModal}
        >
          Claim Reward
        </span>
      ) : (""
      )}
      <Modal
        title={`vSUI Claim Reward`}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total rewards">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                  {/* ${(calculatedBalance * usdValue)?.toFixed(2) || "0.00"} */}  
                </span>
                <span>
                  vSUI Reward: {tokenBalances}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  min={0}
                  max={tokenBalances}
                  value={calculatedBalance}
                  onChange={handleInputChange}
                />
                <button
                  className="max-btn"
                  type="button"
                  onClick={handleMaxClick}
                >
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={tokenLogo}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>vSUI</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          {/*<Slider
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
          />*/}
          <Button
            size="large"
            block
            className="supplyBtn"
            disabled={calculatedBalance <= 0 || calculatedBalance > parseFloat(tokenBalances)}
            onClick={handleClick}
          >
            {calculatedBalance <= 0 ? "Enter Amount" : `Claim ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};
export default RewardModal;
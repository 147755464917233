import { Card, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import Empty from "../../Assets/Svg/Empty.svg";
import RepayModal from "../../Components/Modal/Repay/RepayModal";
import { Table } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import SUI from "../../Assets/Svg/Sui.svg";
import USDC from "../../Assets/Svg/Usdc.svg";
import WETH from "../../Assets/Svg/Weth.svg";
import { FiRefreshCcw } from "react-icons/fi";
import { getIndexAssetData } from "../../Components/Common/Apis/Apis";
import axios from "axios";
import { useWallet } from "@suiet/wallet-kit";


const Borrow = ({ stakes, fetchStakes, loading, usdValue, setBorrowReload, repayReload }) => {
  const [isRotating, setIsRotating] = useState(false);
  const [assetsInfo, setAssetsInfo] = useState({});
  const wallet = useWallet();

  const assetsssss = Object.values(usdValue)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
      asset: info.symbol,
      info,
    }));

  const assets = [
    {
      name: "SUI",
      icon: SUI,
      balance: stakes?.SUI?.borrowBalance?.toFixed(4) || 0.0,
      apy:parseFloat(assetsssss[0]?.info?.borrow_rate || 0)?.toFixed(2) + "%",
      ltv: "70.00%",
    },
    {
      name: "USDC",
      icon: USDC,
      balance: stakes?.USDC?.borrowBalance?.toFixed(4) || 0.0,
      apy:parseFloat(assetsssss[1]?.info?.borrow_rate || 0)?.toFixed(2) + "%",
      ltv: "70.00%",
    },
    {
      name: "WETH",
      icon: WETH,
      balance: stakes?.WETH?.borrowBalance?.toFixed(5) || 0.0,
      apy:parseFloat(assetsssss[2]?.info?.borrow_rate || 0)?.toFixed(2) + "%",
      ltv: "70.00%",
    },
  ];

  const filteredAssets = assets.filter((asset) => {
    const balance = parseFloat(asset.balance);
    if (asset.name === "WETH") {
      return balance > 0.0;
    }
    return balance > 0.0;
  });

  const handleReload = () => {
    fetchStakes();
    fetchTokenInfo()
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 5000);
  };

  const fetchTokenInfo = async () => {
    try {
      const response = await axios.get(getIndexAssetData);
      setAssetsInfo(response.data);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };

  const assetsBal = Object.values(assetsInfo)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
    price: info.price,
    name: info.symbol,
  }));

  const SuiUsdBal = stakes?.SUI?.borrowBalance * assetsBal[0]?.price;
  const USDCUsdBal = stakes?.USDC?.borrowBalance * assetsBal[1]?.price;
  const WETHUsdBal = stakes?.WETH?.borrowBalance * assetsBal[2]?.price;
  const tokenUsdBal = USDCUsdBal + WETHUsdBal + SuiUsdBal;

  useEffect(() => {
    handleReload();
    setBorrowReload(() => handleReload);
  }, []);

  return (
    <Card
      title={
        <div className="d-flex justify-content-between">
          <span>Navi Borrows</span>
          <span className="d-flex align-items-center">
            <h6>
              Total Borrows:&nbsp;
              <strong>${wallet.connected ? tokenUsdBal?.toFixed(2) || "0.00" : "0.00"}</strong>&nbsp;&nbsp;
              <FiRefreshCcw
              style={{ cursor: "pointer" }}
              onClick={handleReload}
              className={isRotating ? "rotateReload" : ""}
              />
            </h6>
          </span>
        </div>
      }
    >
      {filteredAssets.length === 0 ? (
        <div className="text-center">
          <img src={Empty} alt="" width={100} />
          <br />
          <span className="text-muted">Nothing borrowed yet</span>
        </div>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Debt</th>
              <th>APY</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredAssets.map((asset, index) => (
              <tr key={index}>
                <td>
                  <img src={asset.icon} alt="" />
                  <span>{asset.name}</span>
                </td>
                <td>{asset.balance}</td>
                <td className="text-danger">{asset.apy}</td>
                <td>
                  <Dropdown
                    placement="bottom"
                    overlay={
                      <Menu>
                        <Menu.Item key="borrow">
                          <RepayModal
                            tokenLogo={asset.icon}
                            tokenName={asset.name}
                            balance={asset.balance}
                            handleReload={() => handleReload()}
                            usdValue={usdValue}
                            repayReload={repayReload}
                          />
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <BsThreeDots />
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Card>
  );
};

export default Borrow;

import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Card, Dropdown, Menu } from "antd";
import { BsThreeDots } from "react-icons/bs";
import USDC from "../../Assets/Svg/Usdc.svg";
import UserDashboard from "./UserDashboard";
import UserDepositModal from "../../Components/Modal/UserDeposit/UserDeposit";
import axios from "axios";
import {
  apiToken,
  BaseUrl,
  RpcUrlSui_getObject,
} from "../../Components/Common/Apis/Apis";
import { getConfig, pool } from "../../Components/Common/Address/Address";
import { useWallet } from "@suiet/wallet-kit";
import UserWithdrawModal from "../../Components/Modal/UserWithdral/UserWithdral";

function User() {
  const [coinObjectId, setCoinObjectId] = useState("");
  const [balances, setBalances] = useState("0.0");
  const [userbalances, setUserBalances] = useState("0.0");
  console.log(userbalances, "userbalances");
  const [netWorth, setNetWorth] = useState("0.0");
  const [config, setConfig] = useState(null);
  const wallet = useWallet();

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  const fetchBalance = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "suix_getCoins",
        params: [wallet?.address, pool?.USDC?.type, null, 50],
      });
      const data = response?.data?.result?.data || [];
      const totalBalance = data.reduce(
        (acc, obj) => acc + parseFloat(obj.balance || "0"),
        0
      );
      setCoinObjectId(response?.data?.result?.data[0]);
      setBalances(totalBalance / 1e6);
    } catch (error) {
      console.error("Error fetching balance:", error);
      return {
        balance: "0.00",
        coinObjectId: "",
      };
    }
  };

  const fetchNetworth = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          config.usdcTreasury,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setNetWorth(response?.data?.result?.data?.content?.fields?.usdc_amount);
    } catch (error) {
      console.error("Error fetching balance:", error);
      return "0.00";
    }
  };

  async function DepositUser() {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getTotalUserPosition`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken?.token,
          },
        }
      );
      setUserBalances(response.data?.data || "0.0");
    } catch (error) {
      console.error("Error fetching deposit transactions:", error);
    }
  }

  useEffect(() => {
    if (wallet?.address) {
      DepositUser();
      fetchBalance();
      fetchNetworth();
    }
    loadConfig();
  }, [wallet?.address]);

  return (
    <div>
      <div className="w-75 mx-auto">
        <UserDashboard />
        <Card title="Cash in wallet" className="my-4">
          <Table>
            <thead>
              <tr className="text-start">
                <th>Asset</th>
                <th>Balance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-start">
                <td>
                  <img src={USDC} alt="" className="me-2" />
                  USDC
                </td>
                <td>{userbalances || "0.0"}</td>
                <td>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <UserDepositModal
                            netWorth={netWorth}
                            userbalances={userbalances}
                            tokenName={"USDC"}
                            tokenBalances={balances}
                            usdValue={"1"}
                            Object_Id={coinObjectId}
                            fetchBalances={"1"}
                          />
                        </Menu.Item>
                        <Menu.Item>
                          <UserWithdrawModal
                            tokenBalances={userbalances}
                            tokenName={"USDC"}
                            usdValue={"1"}
                            netWorth={netWorth}
                          />
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <BsThreeDots />
                  </Dropdown>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </div>
    </div>
  );
}

export default User;

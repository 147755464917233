import { Card, Col, Row, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import toast from "react-hot-toast";
import { useWallet } from "@suiet/wallet-kit";
import { Table } from "react-bootstrap";
import { apiToken, BaseUrl } from "../Common/Apis/Apis";
import { IoMdArrowRoundBack } from "react-icons/io";

const UserTransaction = () => {
  const [allUserDeposit, setAllUserDeposit] = useState([]);
  const [allUserWithdraw, setAllUserWithdraw] = useState([]);
  const [loading, setLoading] = useState(true);
  const wallet = useWallet();

  async function allDeposit() {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getDepositTransactionUser`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken?.token,
          },
        }
      );
      setAllUserDeposit(response.data?.data || "0.0");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching deposit transactions:", error);
      setLoading(false);
    }
  }

  async function allWithdraw() {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getWithdrawalTransactionOfUser`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken?.token,
          },
        }
      );
      setAllUserWithdraw(response.data?.data || "0.0");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching withdrawal transactions:", error);
      setLoading(false);
    }
  }

  const formatAddress = (address) => {
    return `${address.slice(0, 5)}...${address.slice(-4)}`;
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Copied");
  };

  useEffect(() => {
    if (wallet?.address) {
      allDeposit();
      allWithdraw();
    }
  }, [wallet?.address]);

  const depositTable = (
    <Table>
      <thead>
        <tr className="text-start">
          <th>Date</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Txid</th>
        </tr>
      </thead>
      <tbody>
        {!loading && allUserDeposit?.length > 0 ? (
          allUserDeposit?.map((transaction, index) => (
            <tr key={index}>
              <td>{new Date(transaction?.createdAt)?.toLocaleString()}</td>
              <td>{transaction?.usdc_amount}</td>
              <td>{transaction?.status}</td>
              <td>
                {formatAddress(transaction?.transaction_hash)}{" "}
                <FaCopy
                  className="text-primary"
                  onClick={() => copyToClipboard(transaction?.transaction_hash)}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">
              {loading ? "Loading..." : "No transactions"}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  const withdrawTable = (
    <Table>
      <thead>
        <tr className="text-start">
          <th>Date</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Txid</th>
        </tr>
      </thead>
      <tbody>
        {!loading && allUserWithdraw?.length > 0 ? (
          allUserWithdraw?.map((transaction, index) => (
            <tr key={index}>
              <td>{new Date(transaction?.createdAt)?.toLocaleString()}</td>
              <td>{transaction?.usdc_amount}</td>
              <td>{transaction?.status}</td>
              <td>
                {formatAddress(transaction?.transaction_hash)}{" "}
                <FaCopy
                  className="text-primary"
                  onClick={() => copyToClipboard(transaction?.transaction_hash)}
                />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4">
              {loading ? "Loading..." : "No transactions"}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <div className="container-fluid w-75 my-5">
      <Row>
        <Col span={24}>
          <Card>
          <a href="javascript: history.go(-1)" className="text-decoration-none"><IoMdArrowRoundBack />Go Back</a>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Deposit" key="1">
                {depositTable}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Withdraw" key="2">
                {withdrawTable}
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserTransaction;
